import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/event/importer/eventImporterSelectors';
import EventService from 'src/modules/event/eventService';
import fields from 'src/modules/event/importer/eventImporterFields';
import { i18n } from 'src/i18n';

const eventImporterActions = importerActions(
  'EVENT_IMPORTER',
  selectors,
  async (data, importHash) => {
      console.log('Starting import with:', data);
      try {
          const result = await EventService.import(data, importHash);
          console.log('Import result:', result);
          return result;
      } catch (error) {
          console.error('Error in import action:', error);
          throw error;
      }
  },
  fields,
  i18n('entities.event.importer.fileName'),
);

export default eventImporterActions;